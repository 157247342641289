export class Constant {
    public authData = JSON.parse(localStorage.getItem('admin'));

    public signup = 'authentication/admin/signup';
    public login = 'authentication/admin/login';
    public forgot_password = 'authentication/app/forgot-password';
    public check_otp = 'authentication/app/check-otp';
    public new_password = 'authentication/admin/new-password';
    public change_password = 'authentication/admin/change-password';
    

    public user = 'user';
    public category = 'category';
    public units = 'units';
    public notification = 'notification';
    public qr_code = 'qr-code';
    public qr_code_request = 'qr-code/by-qr-code-request';
    public download_qr_code_excel = 'qr-code/download-qr-code-excel';
    public product = 'product';
    public policy = 'policy';
    public email_setting = 'email-setting';
    public customer_support = 'customer-support';
    public blockUnblock = 'block-unblock';
    public video = 'videos';
    public type = 'TERM_CONDITION';
    public type1 = 'POLICY';    
}