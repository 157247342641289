import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constant } from './constant';
import { environment } from '../../../../environments/environment';

// const API_URL = 'https://xupzip.com:9090/food-manager';
// const API_URL = 'http://192.168.0.25:9090/food-manager';

const API_URL = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})

export class RestApiService {
    API_URL: string;
    authData: any;
    
    constructor(private httpClient: HttpClient,private constant: Constant) {  
        this.API_URL = API_URL;
     }
    postRequest = (methodUrl: string, requestBody: any): Observable<any> => {
        
        const requestUrl = API_URL + '/' + methodUrl;
        return this.httpClient.post(requestUrl, requestBody, this.getHttpHeader());
    }
    postRequestWithoutAuthorization= (methodUrl: string, requestBody: any): Observable<any> => {
        const requestUrl = API_URL + '/' + methodUrl;
        return this.httpClient.post(requestUrl, requestBody);
    }

    postRequestWithParameterRequest = <T>(methodUrl: string, requestBody: any, requestParam: any): Observable<any> => {
        
         const requestUrl = API_URL + '/' + methodUrl;
         return this.httpClient.post(requestUrl, requestBody, { headers: this.getHttpHeader().headers, params: requestParam });
     }


    putRequest = (methodUrl: string, requestBody: any): Observable<any> => {
        const requestUrl = API_URL + '/' + methodUrl;
        return this.httpClient.put(requestUrl, requestBody, this.getHttpHeader());
    }

    putRequestWithParameterRequest = (methodUrl: string, requestBody: any, requestParam: any): Observable<any> => {
       
        const requestUrl = API_URL + '/' + methodUrl;
        return this.httpClient.put(requestUrl, requestBody, { headers: this.getHttpHeader().headers, params: requestParam });
    }

    deleteRequest = (methodUrl: string, id: any): Observable<any> => {
        const requestUrl = API_URL + '/' + methodUrl + '/' + id;
        return this.httpClient.delete(requestUrl, this.getHttpHeader());
    }

    deleteWithParameterRequest = (methodUrl: string, requestParam: any): Observable<any> => {
        const requestUrl = API_URL + '/' + methodUrl ;
        return this.httpClient.delete(requestUrl, { headers: this.getHttpHeader().headers, params: requestParam });
    }

    getRequest = (methodUrl: string): Observable<any> => {
        
        const requestUrl = API_URL + '/' + methodUrl;
        return this.httpClient.get(requestUrl, { headers: this.getHttpHeader().headers});//this.getHttpHeader() { headers: httpHeaders}
    }

    getRequestWithParameter = <T>(methodUrl: string, requestParam: any): Observable<any> => {
        const requestUrl = API_URL + '/' + methodUrl;
        return this.httpClient.get(requestUrl, { headers: this.getHttpHeader().headers, params: requestParam });
    }

    getHttpHeader = () => {
        this.authData = this.constant.authData;
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': this.authData? this.authData.token : '',
            })
           
        };
        console.log(httpOptions)
        return httpOptions;
    }

    getHTTPHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.authData? this.authData.token : '');
        console.log("result"+JSON.stringify(headers))
        return headers;
      }
    }      